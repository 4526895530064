<template>
    <Overview 
        :moduleName="'Klant'"
        :moduleNamePlural="'Klanten'"
        :sort="'name'"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :disableDelete=!isAdmin
        :disableNewButton=!isAdmin
    >
          
        <template #columns>
            <Column field="name" header="Naam" :sortable="true" style="width: 30%"></Column>
            <Column field="city" header="Plaats" :sortable="true" style="width: 30%"></Column>
        </template>
            


        <template #form>
            <CustomerForm ref="ItemForm" :itemData="item"/>
        </template>
    </Overview>
</template>

<script>
import customerService from '@/services/CustomerService';
import Column from 'primevue/column';
import CustomerForm from '@/components/customer/CustomerFormComponent.vue';
import Overview from '@/components/Overview.vue';
import store from '@/store/user'

export default {
    components: {
        Overview,
        Column,
        CustomerForm
    },
    data() {
        return {
            itemService: customerService,
            item: {},
            itemFormRef: null
        }
    },
    computed: {
        isAdmin () {
            return store.getters.isAdmin
        }
    },
    methods: {
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>

<style scoped>

    .ms-n5 {
        margin-left: -40px;
    }

    .table-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    a.fa-stack {
        cursor: pointer;
    }

</style>

<style>
.pi-sort-alt:before {
    content: "";
}
</style>
<template>
    <div class="modal-body">
        <template v-if="loading">
            <i class="fas fa-spinner fa-spin"></i> Even wachten...
        </template>
        <template v-else>
            <div class="row">
                <label for="name" class="col-sm-2 col-form-label">Naam</label>
                <div class="col-sm-8">
                        <input v-model="customer.name" class="form-control form-control-sm" id="name"/>
                </div>
            </div>
            <div class="row">
                <label for="address" class="col-sm-2 col-form-label">Adres</label>
                <div class="col-sm-8">
                    <input v-model="customer.address" class="form-control form-control-sm" id="address"/>
                </div>
            </div>
            <div class="row">
                <label for="postal_code" class="col-sm-2 col-form-label">Postcode</label>
                <div class="col-sm-8">
                    <input v-model="customer.postal_code" class="form-control form-control-sm" id="postal_code"/>
                </div>
            </div>
            <div class="row">
                <label for="city" class="col-sm-2 col-form-label">Plaats</label>
                <div class="col-sm-8">
                    <input v-model="customer.city" class="form-control form-control-sm" id="city"/>
                </div>
            </div>
            
        </template>
    </div>
</template>

<script>

    export default {
        name: 'CustomerListComponent',
        props: ['itemData'],
        data() {
            return {
                customer: {
                    'name': null,
                    'address': null,
                    'postal_code': null,
                    'city':null,
                    'country_id':158
                },
                submitted: false,
                loading: false,
                invalid: {}
            }
        },
        watch: {
            itemData: function (val) {
                this.customer = val    
            },
        },
       methods: {
            validate(){
                this.invalid = {};
                let valid = true;

                let required = document.querySelectorAll('[required]');

                return valid;
            },
            getData() {
                return this.customer
            },
            reset() {
                this.customer = {
                    'name': null,
                    'address': null,
                    'postal_code': null,
                    'city':null,
                    'country_id':158
                },
                this.loading = false
                this.invalid = {}
            },
            setData(data) {
                this.customer = data
            }
        },
        mounted () {
            this.reset()
        }
    }
</script>
